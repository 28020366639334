export default {
  red: '#AC0235',
  red1: '#750223',
  blue: '#4879a6',
  dark_blue: '#1D5E89',
  dark_red: '#931717',
  strong_yellow: '#C8A000',
  maroon: '#800000',
  white: '#ffffff',
  e_blue: '#5E6E7D',
  turquoise: '#07BEBE',
  turquoise_1: '#00898C',
  turquoise_2: '#005D60',
  black_2: '#bfbfbf',
  lightGray: '#b1b1b1',
  gray: '#949494',
  darkGray: '#595959',
  black: '#000000',
  yellow: '#FBBB26',
  green: '#a3cc14',
  annotations: {
    dark_green: '#3C790C',
  },
  dark_pink: '#850B62',
  very_dark_cyan: '#296868',
  moderate_red: '#B8605B',
  strong_orange: '#B86315',
  very_dark_lime_green: '#044A00',
  very_dark_magenta: '#580F54',
  very_dark_blue: '#06356D',
  very_dark_desaturated_orange: '#524021',
  dark_red_2: '#8F2504',
  dark_yellow: '#668503',
  dark_violet: '#3E2683',
  strong_orange_2: '#CC5200',
  lime_green: '#4BAA7D',
  dark_magenta: '#AE0086',
  dark_cyan: '#1C9AAB',
  dark_moderate_violet: '#593D7C',
  very_dark_blue_2: '#031F3B',
  moderate_red_2: '#C73333',
  moderate_green: '#9AC543',
  strong_magenta: '#9900CC',
  very_dark_green: '#435418',
  perception: {
    p1: '#AC0235',
    p2: '#c72858',
    p3: '#005D60',
    p4: '#219498',
    p5: '#07bebe',
    p6: '#84d9d9',
  },
};
